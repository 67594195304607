import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

const AboutPage = () => {
  return (
    <Layout hero={<HeroImage title="About" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <Row>
            <Col sm={12}>
              <p>
                Metalcorp is an Australian steel distribution business that is into everything in steel.
                Our extensive product range includes livestock handling equipment, building materials, structural and tubular steel, fencing, roofing, aluminium and more.</p>
              <p>
                We pride ourselves on delivering the highest levels of customer service and quality products. We have huge stock holdings and our products can be cut to length and delivered to your site.
                Our commitment is to supply competitively priced products, to operate in a safe environment and to deliver outstanding customer service. The successful outcomes of this commitment have enabled Metalcorp to become a leading metals distribution business.
              </p>
              <p>
                Metalcorp operates across Australia with branches and stockists in New South Wales, Queensland, Victoria, South Australia and Western Australia.
                At Metalcorp, we're your rural brand for the land.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center grey-row content-row">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <h1 className="mb-4">SUPPORTING AUSTRALIA FOR OVER 60 YEARS</h1>
              <p>With a strong and innovative history, Metalcorp has developed into one of Australia's leading rural steel distribution businesses. 
              We serve the rural community with durable steel products, 
              using quality Australian-made steel developed with an acute understanding of the needs of Australian farmers. 
              We're in this for the long haul.</p>
              <Link className="button primary" to="/community/australian-heritage">
                Australia Heritage
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </Col>
            <Col sm={6}>
              <StaticImage 
                src="../images/farmer-facing-back.jpeg" 
                alt="We serve the rural community with durable steel products"
                width={480}
                height={360}
                placeholder="blurred" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 d-flex justify-content-center content-row">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <StaticImage 
                src="../images/medical-center.jpeg" 
                alt="National Centre for Farmer Health"
                width={480}
                height={360}
                placeholder="blurred" />
            </Col>
            <Col sm={6}>
              <h1 className="mb-4">NATIONAL CENTRE FOR FARMER HEALTH</h1>
              <p>Metalcorp has teamed up with the National Centre for Farmer Health (NCFH) to make a difference to farmer's lives and 
              give back to the rural community. Our focus is on combatting the “Ripple Effect,” 
              preventing farmers from taking their lives and stopping the enormous destruction this has on our rural communities.</p>
              <Link className="button primary" to="/community/national-centre-farmer-health">
                Learn more
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 d-flex justify-content-center grey-row content-row">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <h1 className="mb-4">TICK OF APPROVAL</h1>
              <p>Metalcorp's livestock handling equipment has received a Safe Farming Tick Of Approval by the National Centre for Farmer Health. 
              A first for the rural sector, the independent report contains the results of Metalcorp's 
              CLASSIC PLUS® Crush, CATTLEMASTER® Crush and the HOBBYMASTER® Vet Crush, which were physically assessed.</p>
              <Link className="button primary" to="/">
                Learn more
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </Col>
            <Col sm={6}>
              <StaticImage 
                src="../images/safe-farming-banner.jpeg" 
                alt="Safe Farming"
                width={480}
                height={360}
                placeholder="blurred" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 mb-3 d-flex justify-content-center content-row">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <StaticImage 
                src="../images/store-locator-laptop.jpeg" 
                alt="Hero image"
                width={480}
                height={360}
                placeholder="blurred" />
            </Col>
            <Col sm={6}>
              <h1 className="mb-4">FIND A STORE</h1>
              <p>With 20 stores nationwide, Metalcorp operates across Australia with branches and stockists in 
              New South Wales, Queensland, Victoria, South Australia, Western Australia and Tasmania (stockists only). </p>
              <Link className="button primary" to="/store-locator">
                Store Locator
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default AboutPage

export const Head = () => {
  return (
    <>
      <title>About | Metalcorp</title>
      <meta name="description" content="Metalcorp is an Australian steel distribution business that is into everything in steel. With huge stock holdings, our products can be cut to length and delivered to site." />
      <meta name="og:description" property="og:description" content="Seamless Online Shopping for Quality Steel and Rural Products. Metalcorp is your rural brand for the land." />
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/opengraph.png" />
    </>
  )
}

